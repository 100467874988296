import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"
import { ServiceContent } from "../components/service/ServiceContent"

const IndexPage = () => (
  <Layout>
    <Seo
      keywords="できること,トナリノ,デザイン,デザイン相談"
      description="各種相談内容や、レザインレビューについて、対応可能な媒体例などをご紹介しています。"
      ogTitle="できること"
      ogDescription="トナリノができることをご紹介"
      ogUrl="https://tonarino.co/service"
    />
    <PageTitleBox title="できること" />
    <section id="serviceContent">
      <ServiceContent />
    </section>
  </Layout>
)

export default IndexPage
