import React, { useCallback, useState } from 'react'
import { Link } from "gatsby"
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export const ServiceContent = () => {
  return (
    <>
      <div className="container pageBodyBox">
        <section id="example">
          <div className="border border-3 border-primary p-3 mb-4">
            <h2 className="fs-3 fw-bold mb-4">たとえば、こんな相談ができます</h2>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold mb-2">デザインについて</h3>
              <ul className="mb-0">
                <li className="mb-0">つくってはみたけれど、なにか違う気がする。でもどこがおかしいのかがわからない</li>
                <li className="mb-0">
                  PhotoshopやCanvaなど、デザインツールを使うことはできるものの、よりよくするためにデザイナーに見てもらいたい
                </li>
                <li className="mb-0">つくったデザインに合わせた印刷手法、紙の材質が知りたい</li>
                <li className="mb-0">
                  技術的なところはデザイナーにお願いしつつ、テキストの差し替えなどは自分で行い効率化したい
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold mb-2">Webサイトについて</h3>
              <ul className="mb-0">
                <li className="mb-0">
                  Webサイトをつくりたいけれど、どんなツールを使えばいいだろう
                </li>
                <li className="mb-0">
                  お客様にWeb制作を依頼されたけれど、どうやって進めていったらよいか相談したい
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold mb-2">同人誌について</h3>
              <ul className="mb-0">
                <li className="mb-0"> 同人誌の表紙やおしながきをつくってみたけれど、よりよくするにはどうしたらいいだろう</li>
                <li className="mb-0">はじめて同人誌をつくるけれど、予算や部数に合わせたおすすめの印刷所が知りたい、相談にのってほしい</li>
              </ul>
            </div>
            <div className="mb-0">
              <h3 className="fs-4 fw-bold mb-2">その他</h3>
              <ul className="mb-0">
                <li className="mb-0">デザイナーとして就職活動中、未経験からデザイナーになりたいけれどどんなことからはじめたら良いのか相談したい</li>
              </ul>
            </div>
          </div>
        </section>
        <section id="example">
          <div className="border border-3 border-secondary p-3 mb-4">
            <h2 className="fs-3 fw-bold mb-4">デザインレビューの一例</h2>
            <h3 class="mb-2">キャンペーン用チラシ</h3>
            <div className="row align-items-center g-0 mb-4">
              <div className="col-5">
                <Zoom>
                  <img
                    src="/images/sample/advice_flyer_before.jpg"
                    width="400"
                    className="mw-100 mb-0"
                  />
                </Zoom>
              </div>
              <div className="col-2 text-center">
                <i className="bi bi-chevron-right fs-1 text-primary"></i>
              </div>
              <div className="col-5">
                <Zoom>
                  <img
                    src="/images/sample/advice_flyer_after.jpg"
                    width="400"
                    className="mw-100 mb-0"
                  />
                </Zoom>
              </div>
            </div>
            <h3 class="mb-2">店舗オープン用のダイレクトメール</h3>
            <div className="row align-items-center g-0 mb-4">
              <div className="col-5">
                <Zoom>
                  <img
                    src="/images/sample/advice_dm_before.jpg"
                    width="400"
                    className="mw-100 mb-0 border"
                  />
                </Zoom>
              </div>
              <div className="col-2 text-center">
                <i className="bi bi-chevron-right fs-1 text-primary"></i>
              </div>
              <div className="col-5">
                <Zoom>
                  <img
                    src="/images/sample/advice_dm_after.jpg"
                    width="400"
                    className="mw-100 mb-0 border"
                  />
                </Zoom>
              </div>
            </div>
            <h3 class="mb-2">個人で発行するイラスト同人誌</h3>
            <div className="row align-items-center g-0 mb-0">
              <div className="col-5">
                <Zoom>
                  <img
                    src="/images/sample/advice_book_before.jpg"
                    width="400"
                    className="mw-100 mb-0"
                  />
                </Zoom>
              </div>
              <div className="col-2 text-center">
                <i className="bi bi-chevron-right fs-1 text-primary"></i>
              </div>
              <div className="col-5">
                <Zoom>
                  <img
                    src="/images/sample/advice_book_after.jpg"
                    width="400"
                    className="mw-100 mb-0 border"
                  />
                </Zoom>
              </div>
            </div>
          </div>
        </section>
        <p>
          Illustrator、Photoshop、XD（すべてCreative Cloud）、Figmaの範囲で可能な作業をご提案いたします。<br />
          CanvaやCLIP STUDIOなどのツールも内容によっては可能ですのでご相談ください。<br />
          Photoshop ElementsについてはPhotoshopでできる範囲内でご提案します。
        </p>
        <p className="text-center mt-4 mb-5">
          <Link to="/plans#designImproving" className="btn btn-primary btn-fixed-width rounded-pill py-3">
            詳しいアドバイス内容を見る
          </Link>
        </p>
        <section id="field">
          <h2 className="fs-3 fw-bold mb-2">対応可能な媒体の例</h2>
          <p>ロゴ、名刺、封筒、チラシ、広告、装丁、画像加工、切り抜き、二次創作、アメニティグッズ、Webデザイン、UIデザイン等</p>
        </section>
        <section id="application">
          <h2 className="fs-3 fw-bold mb-2">アプリケーション</h2>
          <p>
            Illustrator、Photoshop、XD（すべてCreative Cloud）、Figmaの範囲で可能な作業をご提案いたします。<br />
            CanvaやCLIP STUDIOなどのツールも内容によっては可能ですのでご相談ください。<br />
            Photoshop ElementsについてはPhotoshopでできる範囲内でご提案します。
          </p>
        </section>
        <p className="text-center mt-4 mb-5">
          <Link to="/plans" className="btn btn-primary btn-fixed-width rounded-pill py-3">
            詳しいプランをみる
          </Link>
        </p>
      </div>
    </>
  )
}
